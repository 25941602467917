import { Modal, Row, Col, Button } from 'react-bootstrap';
import React, { useEffect } from 'react';
import CardNavigationTab from '~/Components/CardNavigationTab';
import { useState } from 'react';
import ProspectoDetalhes from '~/Components/Prospectos/ProspectoDetalhes/ProspectoDetalhes';
import DetalhesAtivo from './DetalhesAtivo';
import * as api from '~/Services/Service';
import { toast } from 'react-toastify';
import DetalhesEstorno from './DetalhesEstorno';
import DetalhesManutencaoVenda from './DetalhesManutencaoVenda';
import DetalhesPreVendaSemConjuge from './DetalhesPreVendaSemConjuge';
import { FaWindowClose } from 'react-icons/fa';
import AuthStorage from '~/Utils/AuthStorage';
const ModalDetalhes = ({ mostrarModal, setMostrarModal, linhaSelecionada }) => {
  const [tab, setTab] = useState('detalhes-pre-venda');
  const [ativo, setAtivo] = useState(null);
  const podeAutorizarSemConjuge = AuthStorage.temCaracteristica(242);

  const habilitarCardEstorno = () => {
    if (AuthStorage.temCaracteristica(551)) {
      return true;
    }
    if (AuthStorage.getUser().usuario === linhaSelecionada.codigo_corretor) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const buscarAtivoDetalhes = async () => {
      if (!linhaSelecionada.pre_venda) return;
      try {
        const response = await api.detalharPreVenda(linhaSelecionada.pre_venda);
        if (response) {
          setAtivo(response);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarAtivoDetalhes();
  }, []);

  const verificaTab = tab => {
    setTab(tab);
  };

  return (
    <Modal
      show={mostrarModal}
      onHide={() => setMostrarModal(false)}
      size={'xl'}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Detalhes Pré-Venda N° {linhaSelecionada.pre_venda}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Row>
          <Col className="">
            <CardNavigationTab
              activeTab={tab}
              onChangeTab={verificaTab}
              tabs={[
                {
                  label: 'Detalhes da venda',
                  url: 'detalhes-pre-venda',
                  component: (
                    <DetalhesAtivo
                      linhaSelecionada={linhaSelecionada}
                      ativo={ativo}
                    />
                  ),
                },
                {
                  label: 'Prospecto',
                  url: 'cliente-prospecto',
                  component: (
                    <ProspectoDetalhes
                      hiddenNavbar={true}
                      hiddenSearch={true}
                      showCancel={true}
                      prospectoID={linhaSelecionada.codigo_prospecto}
                    />
                  ),
                },
                habilitarCardEstorno() && {
                  label: 'Estorno',
                  url: 'estorno',
                  component: (
                    <DetalhesEstorno
                      proposta={linhaSelecionada.codigo_proposta}
                      ativo={ativo}
                      corretorVenda={linhaSelecionada.codigo_corretor}
                    />
                  ),
                },
                {
                  label: 'Manutenção Venda',
                  url: 'manutencao-venda',
                  component: (
                    <DetalhesManutencaoVenda
                      preVenda={linhaSelecionada.pre_venda}
                      codigoProposta={linhaSelecionada.codigo_proposta}
                      seq={linhaSelecionada.seq}
                      ge={linhaSelecionada.ge_cliente.split(' / ')[0]}
                    />
                  ),
                },
                podeAutorizarSemConjuge && {
                  label: 'Pré-Venda Sem Cônjuge',
                  url: 'preVenda-sem-conjuge',
                  component: (
                    <DetalhesPreVendaSemConjuge
                      preVenda={linhaSelecionada.pre_venda}
                    />
                  ),
                },
              ].filter(Boolean)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant={'light'}
            type="submit"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setMostrarModal(false);
            }}
          >
            <FaWindowClose />
            <span className="ml-2 d-xs-none">Fechar</span>
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetalhes;
