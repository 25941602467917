import VMasker from 'vanilla-masker';
import * as constants from '../constant';
import _reduce from 'lodash/reduce';
import axios from 'axios';
import LocalStorage from '~/Utils/LocalStorage';
import moment from 'moment';
require('moment/locale/pt-br');
moment.locale('pt-br');

export const serialize = function(obj) {
  var str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

/**
 *
 * @param {*} str
 * @returns Object
 */
export const unserialize = str => {
  if (!str) return {};

  return str
    .replace(/(^\?)/, '')
    .split('&')
    .reduce((prev, curr) => {
      curr = curr.split('=');
      return {
        ...prev,
        [decodeURIComponent(curr[0])]: decodeURIComponent(curr[1]),
      };
    }, {});
};

export const mask = {
  phone: phone => {
    return constants.MASK_CEL;
  },
  celular: phone => {
    if (!phone) {
      return constants.MASK_CEL;
    }

    let numbers = phone.replace(/\s|-|_|\.|\(|\)/g, '');
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.length;
    }

    return numberLength > 10 ? constants.MASK_CEL_9 : constants.MASK_CEL;
  },
  cpf_cnpj: str => {
    if (!str) {
      return constants.MASK_CPF;
    }

    let numbers = str.replace(/[^0-9]+/g, '');

    if (!numbers) {
      return constants.MASK_CPF;
    }

    return numbers.length > 11 ? constants.MASK_CNPJ : constants.MASK_CPF;
  },
};

export const MIN_SQL_DATE = new Date(1753, 1, 1);

export const cloneDate = date => moment(date);
export const createDate = (string, format = constants.DATE_FORMAT_SERVER) =>
  moment(string, format);
export const getToday = () => moment();

export const format = {
  phone: value => {
    if (!value) {
      return '';
    }
    value = value.match(/\d/g);

    return value ? VMasker.toPattern(value, '(99) 9999-9999') : '';
  },
  cpf: value => {
    if (!value) {
      return '';
    }
    value = value.match(/\d/g);

    return value.length > 11
      ? VMasker.toPattern(value, '99.999.999/9999-99')
      : VMasker.toPattern(value, '999.999.999-99');
  },
  cep: value => {
    if (!value) {
      return '';
    }
    value = value.match(/\d/g);

    return value ? VMasker.toPattern(value, '99999-999') : '';
  },
  celular: value => {
    if (!value) {
      return '';
    }
    value = value.match(/\d/g);

    if (value.join('').length > 10) {
      return value ? VMasker.toPattern(value, '(99) 99999-9999') : '';
    } else {
      return value ? VMasker.toPattern(value, '(99) 9999-9999') : '';
    }
  },
  date: (string, format = constants.DATE_DEFAULT_FORMAT) => {
    return createDate(string).format(format);
  },
  datetime: (string, format = constants.DATETIME_DEFAULT_FORMAT) => {
    return createDate(string).format(format);
  },
  timestamp: (string, format = constants.DATE_FORMAT_SERVER) => {
    return createDate(string, format).unix();
  },
  clear: str => {
    if (!str) return '';
    return str.replace(/[^0-9]+/g, '');
  },
};

export const capitalize = str => {
  if (!str) {
    return str;
  }

  return (
    str &&
    str
      .split(' ')
      .map(item => {
        item = item.toLowerCase().split('');
        if (item[0]) {
          item[0] = item[0].toUpperCase();
        }
        return item.join('');
      })
      .join(' ')
  );
};

//Altera os valores null das propriedades de um objeto para string vazia
export const nullPropertiesToString = obj =>
  _reduce(
    obj,
    (result, value, key) => {
      result[key] = value === null ? '' : value;
      return result;
    },
    {}
  );

export const formatarComZero = (mesa, length = 2) => {
  let str = '' + mesa;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
};

export const formatarMonetario = (
  valor,
  returnIfNoValue = true,
  prefix = 'R$ '
) => {
  if (!valor) {
    if (returnIfNoValue) return;
    else return `${prefix}0,00`;
  }
  valor = valor.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${prefix}${valor}`;
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const financeiro = {
  price: {
    calcularValorFuturo: (valorAFinanciar, quantidadeParcelas, jurosPorMes) => {
      const valorParcela = this.calcularValorParcela(
        valorAFinanciar,
        quantidadeParcelas,
        jurosPorMes
      );
      return valorParcela * quantidadeParcelas;
    },
    calcularValorParcela: (
      valorAFinanciar,
      quantidadeParcelas,
      jurosPorMes
    ) => {
      if (jurosPorMes === 0 || jurosPorMes === -1)
        return valorAFinanciar / quantidadeParcelas;

      const pow = Math.pow(1.0 + jurosPorMes, quantidadeParcelas);
      const a = pow * jurosPorMes;
      const b = pow - 1.0;
      return (valorAFinanciar * a) / b;
    },
  },
};

export const contraSenhaHelper = {
  calcularDicaContraSenha: baseContraSenha => {
    const now = new Date();
    return baseContraSenha * now.getDate() * (now.getMonth() + 1);
  },
  calcularContraSenha: (totalSinal, baseContraSenha) => {
    return baseContraSenha * Math.floor(totalSinal);
  },
  calcularBaseContraSenha: (totalSinal, codigoBase) => {
    const now = new Date();
    let baseCS = 0;
    const cvalor = Math.floor(totalSinal).toString();

    for (const c of cvalor) baseCS += Number(c);

    baseCS += codigoBase + now.getFullYear() + now.getMonth() + now.getDate();
    while (baseCS > 15) {
      if (baseCS % 2 === 0) baseCS = Math.floor(baseCS / 2);
      else baseCS = Math.floor(baseCS / 3);
    }
    baseCS = Math.floor(baseCS);
    if (baseCS === 0) baseCS = 3;
    return Number(baseCS);
  },
};

export const downloadFileViaUrl = url => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
  a.remove();
};

export const openPopup = (url, nome, width, height, top, left) => {
  if (nome == '') nome = '';
  nome =
    typeof nome === 'undefined' ? new Date().getTime() : nome.replace(' ', '_');
  width =
    typeof width === 'undefined' ? window.screen.width - 150 : parseInt(width);
  height =
    typeof height === 'undefined'
      ? window.screen.height - 150
      : parseInt(height);
  top =
    typeof top === 'undefined'
      ? window.screen.height / 2 - height / 2
      : parseInt(top);
  left =
    typeof left === 'undefined'
      ? window.screen.width / 2 - width / 2
      : parseInt(left);
  var top, left;
  var closed = true;
  try {
    closed = window['popup_' + nome].closed;
  } catch (ex) {}
  if (typeof window['popup_' + nome] === 'undefined' || closed) {
    try {
      window['popup_' + nome] = window.open(
        url,
        nome,
        'scrollbars=1, menubar=0, resizable=0, directories=0, location=0, toolbar=0, status=0, top=' +
          top +
          ', left=' +
          left +
          ', width=' +
          width +
          ', height=' +
          height
      );
    } catch (ex) {
      delete window['popup_' + nome];
    }
  } else window['popup_' + nome].location.href = url;
  if (typeof window['popup_' + nome] === 'undefined')
    alert(
      'O link n\u00e3o p\u00f4de ser aberto.\nPor favor, verifique se o bloqueador de pop-ups est\u00e1 ativado.'
    );
  else window['popup_' + nome].focus();
};

export const salvarEmExcel = async (dados, urlEp) => {
  try {
    const request = dados;

    let token = LocalStorage.get(constants.AUTH_KEY)
      ? 'Bearer ' + LocalStorage.get(constants.AUTH_KEY)
      : null;

    const response = await axios.post(constants.APIBASE + urlEp, request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (response) {
      const header = response.headers['content-disposition'];
      const filename = header.match(/filename=(.+)/)[1];
      const blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    }
  } catch (error) {
    console.log(error.message);
  }
};
