import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatarMonetario } from '~/Utils/Helpers';
import * as helpers from '../../Utils/Helpers';
import Loading from '../Loading';

const calcularPorcentagem = (valorOriginal, valorEfetivado) => {
  const diferenca = (valorEfetivado - valorOriginal) / valorOriginal;
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(diferenca);
};

const obterDescricaoFase = fase => {
  switch (fase) {
    case 2:
      return 'Corretor - Cadastro Rápido';
    case 3:
      return 'Financeiro - Valor Não Entregue';
    case 4:
      return 'Secretaria de Vendas - Lançamento';
    case 5:
      return 'Corretor - Venda pendente para regularização';
    case 6:
      return 'Secretaria de Vendas - Emissão Contrato';
    case 7:
      return 'Cliente - Aguardando Assinatura';
    case 8:
      return 'Diretoria - Aguardando Assinatura';
    case 9:
      return 'Financeiro - Aguardando Quitar Sinal';
    case 10:
      return 'Cliente - Contrato Enviado';
    default:
      return '';
  }
};
const DetalhesAtivo = ({ linhaSelecionada, ativo }) => {
  return (
    <>
      {ativo && (
        <div className="mt-1">
          <h4>Informações do Terreno</h4>
          <Row className="mt-4">
            <Col md="6">
              <small>Empreendimento:</small>{' '}
              <strong>{linhaSelecionada.empreendimento}</strong>
            </Col>
            <Col md="6">
              <small>Ativo:</small> <strong>{ativo.codigo}</strong>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <small>Área:</small> <strong>{ativo.area}m²</strong>
            </Col>
            <Col md="6">
              <small>Quadra</small> <strong>{linhaSelecionada.qd}</strong>{' '}
              <small>Lote</small> <strong>{linhaSelecionada.lt}</strong>
            </Col>
          </Row>

          <Row>
            {ativo.matricula && (
              <Col md="6">
                <small>Matrícula: </small>
                <strong>{ativo.matricula}</strong>
              </Col>
            )}
            {ativo.link_prefeitura && (
              <Col md="6">
                <strong>
                  <a
                    href={ativo.link_prefeitura}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acessar site da prefeitura
                  </a>
                </strong>
              </Col>
            )}
          </Row>
          <hr />
          <h4 className="mt-4">Informações da Venda</h4>
          <Row className="mt-4">
            <Col md="4">
              <small>Data da venda:</small>
              <strong className="d-block">
                {helpers.format.datetime(ativo.data_venda)}
              </strong>
            </Col>
            <Col md="8">
              <small>Fase:</small>
              <strong className="d-block">
                {linhaSelecionada.fase} -
                {obterDescricaoFase(linhaSelecionada.fase)}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <small>Valor Venda:</small>
              <strong className="d-block">
                {formatarMonetario(ativo.vv_original)}
              </strong>
            </Col>
            {ativo.vv_original !== ativo.vv_efetivado && (
              <>
                <Col md="4">
                  <small>Valor venda efetivado:</small>
                  <strong className="d-block">
                    {formatarMonetario(ativo.vv_efetivado)}
                  </strong>
                </Col>
                <Col md="4">
                  <small>Diferença valor venda:</small>
                  <strong className="d-block">
                    {calcularPorcentagem(ativo.vv_original, ativo.vv_efetivado)}
                  </strong>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="4">
              <small>Sinal:</small>
              <strong className="d-block">
                {formatarMonetario(ativo.sinal_original)}
              </strong>
            </Col>
            {ativo.sinal_original !== ativo.sinal_efetivado && (
              <>
                <Col md="4">
                  <small>Sinal efetivado:</small>
                  <strong className="d-block">
                    {formatarMonetario(ativo.sinal_efetivado)}
                  </strong>
                </Col>
                <Col md="4">
                  <small>Diferença valor sinal:</small>
                  <strong className="d-block">
                    {calcularPorcentagem(
                      ativo.sinal_original,
                      ativo.sinal_efetivado
                    )}
                  </strong>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="4">
              <small>Usuário:</small>
              <strong className="d-block">{ativo.usuario_proposta}</strong>
            </Col>
            {ativo.usuario_proposta !== ativo.usuario_pre_venda && (
              <Col md="4">
                <small>Usuário pré-venda:</small>
                <strong className="d-block">{ativo.usuario_pre_venda}</strong>
              </Col>
            )}
            {ativo.codigo_prospecto_construtor > 0 && (
              <Col md="4">
                <small>Prospecto construtor:</small>
                <strong className="d-block">
                  {ativo.codigo_prospecto_construtor}
                </strong>
              </Col>
            )}
          </Row>
          <Row>
            {ativo.cheque_bonus_aplicado > 0 && (
              <Col md="4">
                <small>Cheque bônus aplicado: </small>
                <strong className="d-block">
                  {ativo.cheque_bonus_aplicado}
                </strong>
              </Col>
            )}
            {ativo.codigo_desconto > 0 && (
              <>
                <Col md="4">
                  <small>Solicitação de desconto: </small>
                  <strong className="d-block">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        'https://www.revobrasil.com.br/Extranet/Descontos/Inserir.aspx?ID=' +
                        ativo.codigo_desconto
                      }
                    >
                      {ativo.codigo_desconto}
                    </a>
                  </strong>
                </Col>
                <Col md="4">
                  <small>Aprovador por: </small>
                  <strong className="d-block">
                    {ativo.nome_aprovador_desconto}
                  </strong>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="6">
              <small>Tabela:</small>
              <strong className="d-block">
                {ativo.codigo_tabela} - {ativo.descricao_tabela}
              </strong>
            </Col>
          </Row>
        </div>
      )}
      {!ativo && (
        <div>
          <Loading></Loading>
        </div>
      )}
    </>
  );
};

export default DetalhesAtivo;
