import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Header from '~/Components/Header/Header';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { default as Selector } from 'react-select';
import Loading from '~/Components/Loading';
import DateMask from '~/Components/DateMask';
import * as constants from '~/constant';
import * as api from '~/Services/Service';
import MaskedInput from 'react-text-mask';
import { Row, Col, Button, Form, Pagination } from 'react-bootstrap';
import { FaSearch, FaFileExcel } from 'react-icons/fa';
import { formatarMonetario } from '~/Utils/Helpers';
import { salvarEmExcel } from '~/Utils/Helpers';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const mapStateToProps = state => ({
  userLogged: state.auth.user,
});

const relatorioEstorno = ({}) => {
  const [estornos, setEstornos] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);

  const empreendimentos = useSelector(state => state.empreendimentos);
  const corretores = useSelector(state => state.corretor);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const totalPages = Math.ceil(estornos.length / recordsPerPage);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const sortedEstornos = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...estornos].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
      return sorted;
    }
    return estornos;
  }, [estornos, sortConfig]);

  const currentRecords = React.useMemo(() => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    return sortedEstornos.slice(indexOfFirstRecord, indexOfLastRecord);
  }, [sortedEstornos, currentPage, recordsPerPage]);

  const ordenar = column => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const trocarPagina = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const optionsEmpreendimentos = [
    { value: '', label: 'Todos empreendimentos' },
    ...empreendimentos.sort.map(item => ({
      value: item.codigo,
      label: item.codigo + ' - ' + item.descricao,
    })),
  ];

  const optionsCorretores = [
    { value: '', label: 'Todos...' },
    ...corretores.listaCorretores.map(cor => ({
      value: cor.codigo,
      label: cor.codigo + ' - ' + cor.nome,
    })),
  ];

  const buscarRegistros = async filtro => {
    try {
      setCarregando(true);
      const filtroLimpo = Object.fromEntries(
        Object.entries(filtro).filter(
          ([_, value]) => value !== '' && value !== null && value !== undefined
        )
      );
      const response = await api.buscarDadosEstorno(filtroLimpo);
      if (response) {
        setEstornos(response);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCarregando(false);
      setCurrentPage(1);
    }
  };

  return (
    <div>
      <Header title="Relatório de Estorno"></Header>
      <Formik
        initialValues={{
          codigo_proposta: '',
          data_inicial: '',
          data_final: '',
          codigo_empreendimento: '',
          codigo_corretor: '',
          codigo_pre_venda: '',
        }}
        onSubmit={filtro => {
          buscarRegistros(filtro);
        }}
      >
        {({ handleSubmit, setFieldValue, errors, touched, values }) => (
          <Form
            className="container-fluid bg-white p-3"
            onSubmit={handleSubmit}
          >
            <Row>
              <Col xs={12} sm={6} md={4} lg={2}>
                <Form.Group controlId="codigo_proposta">
                  <Form.Label>Proposta</Form.Label>
                  <Field
                    type="text"
                    name="codigo_proposta"
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group controlId="codigo_empreendimento">
                  <Form.Label>Empreendimento</Form.Label>
                  <Selector
                    noOptionsMessage={() => 'Nenhum empreendimento encontrado'}
                    placeholder="Buscar..."
                    options={optionsEmpreendimentos}
                    onChange={selectedOption => {
                      setFieldValue(
                        'codigo_empreendimento',
                        selectedOption ? selectedOption.value : ''
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group controlId="codigo_corretor">
                  <Form.Label>Corretor</Form.Label>
                  <Selector
                    noOptionsMessage={() => 'Não encontrado'}
                    placeholder="Buscar..."
                    options={optionsCorretores}
                    onChange={selectedOption => {
                      setFieldValue(
                        'codigo_corretor',
                        selectedOption ? selectedOption.value : ''
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={3} lg={2}>
                <DateMask
                  initialValue={values.data_inicial}
                  onValidate={value => setFieldValue('data_inicial', value)}
                  restrictToSqlMinDate
                  allowPast
                >
                  {({ dateError, dateValue, setDate }) => (
                    <Form.Group controlId="values.data_inicial">
                      <Form.Label>Data inicial</Form.Label>
                      <MaskedInput
                        name="data_inicial"
                        type="tel"
                        guide={false}
                        className={`form-control ${((errors.data_inicial &&
                          touched.data_inicial) ||
                          dateError) &&
                          'is-invalid'}`}
                        placeholder="Ex.: 01/01/2019"
                        mask={constants.MASK_DATE}
                        value={dateValue}
                        onChange={e => setDate(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.data_inicial}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {dateError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </DateMask>
              </Col>
              <Col xs={12} sm={6} md={3} lg={2}>
                <DateMask
                  initialValue={values.data_final}
                  onValidate={value => setFieldValue('data_final', value)}
                  restrictToSqlMinDate
                  allowPast
                >
                  {({ dateError, dateValue, setDate }) => (
                    <Form.Group controlId="values.data_final">
                      <Form.Label>Data final</Form.Label>
                      <MaskedInput
                        name="data_final"
                        type="tel"
                        guide={false}
                        className={`form-control ${((errors.data_final &&
                          touched.data_final) ||
                          dateError) &&
                          'is-invalid'}`}
                        placeholder="Ex.: 01/01/2019"
                        mask={constants.MASK_DATE}
                        value={dateValue}
                        onChange={e => setDate(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.data_final}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {dateError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </DateMask>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={4} lg={2}>
                <Form.Group controlId="codigo_pre_venda">
                  <Form.Label>Pré-venda</Form.Label>
                  <Field
                    type="text"
                    name="codigo_pre_venda"
                    className="form-control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-itens-center">
              <Button
                disabled={Object.values(errors).length > 0 || carregando}
                variant="success"
                type="submit"
                className="col-xs-12 col-sm-6 col-md-4 col-lg-1"
              >
                <FaSearch />
                <span className="d-xs-none"> Buscar</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {carregando && <Loading />}
      {!carregando && currentRecords.length > 0 && (
        <div className="container-fluid bg-white table-responsive">
          <Table className="table table-striped table-bordered table-hover table-sm">
            <Thead>
              <Tr className="text-center">
                <Th
                  onClick={() => ordenar('codigo_pre_venda')}
                  style={{ cursor: 'pointer' }}
                >
                  Pré-venda
                </Th>
                <Th
                  onClick={() => ordenar('codigo_proposta')}
                  style={{ cursor: 'pointer' }}
                >
                  Proposta
                </Th>
                <Th
                  onClick={() => ordenar('codigo_imovel')}
                  style={{ cursor: 'pointer' }}
                >
                  Imóvel
                </Th>
                <Th onClick={() => ordenar('qd')} style={{ cursor: 'pointer' }}>
                  QD
                </Th>
                <Th
                  onClick={() => ordenar('lote')}
                  style={{ cursor: 'pointer' }}
                >
                  Lote
                </Th>
                <Th
                  onClick={() => ordenar('data_venda')}
                  style={{ cursor: 'pointer' }}
                >
                  Data Venda
                </Th>
                <Th
                  onClick={() => ordenar('data_estorno')}
                  style={{ cursor: 'pointer' }}
                >
                  Data Estorno
                </Th>
                <Th
                  onClick={() => ordenar('valor')}
                  style={{ cursor: 'pointer' }}
                >
                  Valor
                </Th>
                <Th
                  onClick={() => ordenar('corretor')}
                  style={{ cursor: 'pointer' }}
                >
                  Corretor
                </Th>
                <Th
                  onClick={() => ordenar('nome_prospecto')}
                  style={{ cursor: 'pointer' }}
                >
                  GE/Nome/Código
                </Th>
                <Th
                  onClick={() => ordenar('empreendimento')}
                  style={{ cursor: 'pointer' }}
                >
                  Empreendimento
                </Th>
              </Tr>
            </Thead>
            <Tbody className="text-center">
              {currentRecords.length > 0 &&
                currentRecords.map((item, index) => (
                  <Tr
                    key={index}
                    style={{
                      fontWeight: linhaSelecionada === item ? 'bold' : 'normal',
                      cursor: 'pointer',
                      transition: 'background-color 0.1s ease',
                    }}
                    onClick={() => setLinhaSelecionada(item)}
                  >
                    <Td>{item.codigo_pre_venda}</Td>
                    <Td>{item.codigo_proposta}</Td>
                    <Td>{item.codigo_imovel}</Td>
                    <Td>{item.qd}</Td>
                    <Td>{item.lote}</Td>
                    <Td>
                      {new Intl.DateTimeFormat('pt-BR').format(
                        new Date(item.data_venda)
                      )}
                    </Td>
                    <Td>
                      {new Intl.DateTimeFormat('pt-BR').format(
                        new Date(item.data_estorno)
                      )}
                    </Td>
                    <Td>{formatarMonetario(item.valor)}</Td>
                    <Td>{item.corretor}</Td>
                    <Td>
                      {item.ge}/{item.nome_prospecto}/{item.codigo_prospecto}
                    </Td>
                    <Td>{item.empreendimento}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              type="submit"
              onClick={() => {
                salvarEmExcel(estornos, '/exportador/excel/relatorio-estorno');
              }}
            >
              <FaFileExcel />
              <span className="ml-2 d-xs-none">Salvar em EXCEL</span>
            </Button>
          </div>
          <div>
            <Pagination>
              <Pagination.First
                onClick={() => trocarPagina(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => trocarPagina(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {totalPages > 10 && currentPage > 5 && (
                <Pagination.Item onClick={() => trocarPagina(1)}>
                  1
                </Pagination.Item>
              )}
              {totalPages > 10 && currentPage > 5 && <Pagination.Ellipsis />}

              {[...Array(totalPages).keys()]
                .slice(
                  Math.max(0, currentPage - 6),
                  Math.min(totalPages, currentPage + 4)
                )
                .map(number => (
                  <Pagination.Item
                    key={number}
                    active={currentPage === number + 1}
                    onClick={() => trocarPagina(number + 1)}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}

              {totalPages > 10 && currentPage < totalPages - 4 && (
                <Pagination.Ellipsis />
              )}
              {totalPages > 10 && currentPage < totalPages - 4 && (
                <Pagination.Item onClick={() => trocarPagina(totalPages)}>
                  {totalPages}
                </Pagination.Item>
              )}
              <Pagination.Next
                onClick={() => trocarPagina(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => trocarPagina(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(relatorioEstorno);
