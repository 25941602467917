import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Table, Form, Button } from 'react-bootstrap';
import { default as Selector } from 'react-select';
import { useSelector } from 'react-redux';
import Loading from '../Loading';
import {
  FaFileSignature,
  FaExclamationTriangle,
  FaSave,
  FaTrash,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as api from '~/Services/Service';

const DetalhesManutencaoVenda = ({ preVenda, codigoProposta, seq, ge }) => {
  const [dadosManutencaoVenda, setDadosManutencaoVenda] = useState(null);
  const [sociedade, setSociedade] = useState(null);

  const [corretorSelecionado, setCorretorSelecionado] = useState('');
  const [porcentagem, setPorcentagem] = useState('');
  const [isLoading, setIsLoading] = useState(null);

  const gerentesCorretores = useSelector(state => state.corretor);

  const optionsCorretores = [
    { value: '', label: 'Todos os corretores' },
    ...gerentesCorretores.listaCorretores.map(cor => ({
      value: cor.codigo,
      label: cor.codigo + ' - ' + cor.nome,
    })),
  ];

  const obterDadosManutencaoVenda = async preVenda => {
    try {
      let dados = await api.obterDadosManutencaoVenda(preVenda);
      setDadosManutencaoVenda(dados);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const obterDadosSociedade = async (codigoProposta, seq) => {
    try {
      let dados = await api.obterSociedadeManutencaoVenda(codigoProposta, seq);
      if (dados) {
        setSociedade(dados);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    obterDadosManutencaoVenda(preVenda);
    obterDadosSociedade(codigoProposta, seq);
  }, []);

  useEffect(() => {
    if (sociedade && sociedade.codigo_corretor) {
      const corretorInicial = optionsCorretores.find(
        corretor => corretor.value === sociedade.codigo_corretor
      );
      setCorretorSelecionado(corretorInicial);
    }
    if (sociedade && sociedade.porcentagem) {
      setPorcentagem(sociedade.porcentagem);
    }
  }, [sociedade]);

  const limparCampos = () => {
    setCorretorSelecionado('');
    setPorcentagem('');
  };

  const bloquearEdicaoDeRegistros = statusDocDigital => {
    if (
      statusDocDigital === 9 || // 9 -> Uploaded
      statusDocDigital === 6 || // 6 -> Pendente Assinaturas Setpar
      statusDocDigital === 5 // 5 -> Pendente Assinaturas Clientes
    ) {
      return true;
    }
    return false;
  };

  const existeBloqueio = () => {
    if (dadosManutencaoVenda !== null && dadosManutencaoVenda.length > 0) {
      for (let i = 0; i < dadosManutencaoVenda.length; i++) {
        if (
          dadosManutencaoVenda[i].doc_digital_status === 9 ||
          dadosManutencaoVenda[i].doc_digital_status === 6 ||
          dadosManutencaoVenda[i].doc_digital_status === 5
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const assinarOuNaoAssinarContrato = async (item, acao) => {
    try {
      const request = {
        incluir_assinar: acao === 'Assinar' ? true : false,
        codigo_conta: item.codigo_conta,
        ge: item.ge,
        codigo_pre_venda: preVenda,
        seq: item.seq,
      };
      let response = await api.assinarContratoConjugeSocio(request);
      if (response) {
        toast.success(response.message);
        obterDadosManutencaoVenda(preVenda);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const incluirOuRemoverSocio = async (item, acao) => {
    setIsLoading(true);
    try {
      const request = {
        incluir_assinar: acao === 'Incluir' ? true : false,
        codigo_conta: item.codigo_conta,
        ge: item.ge,
        codigo_pre_venda: preVenda,
        seq: item.seq,
      };
      let response = await api.incluirConjugeSocioPreVenda(request);
      if (response) {
        toast.success(response.message);
        obterDadosManutencaoVenda(preVenda);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const salvarSociedade = async porcentagem => {
    setIsLoading(true);
    try {
      const request = {
        percentual: porcentagem ? porcentagem : 0,
        codigo_corretor: corretorSelecionado ? corretorSelecionado.value : 0,
        codigo_proposta: codigoProposta,
        seq: seq,
        ge: ge,
        codigo_usuario_criacao:
          sociedade && sociedade.codigo_usuario_criacao
            ? sociedade.codigo_usuario_criacao
            : 0,
      };

      let response = await api.incluirSociedade(request);
      if (response) {
        toast.success(response.message);
        obterDadosSociedade(codigoProposta, seq);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const excluirSociedade = async () => {
    setIsLoading(true);
    try {
      const request = {
        codigo_proposta: codigoProposta,
        seq: seq,
        codigo_usuario_criacao:
          sociedade && sociedade.codigo_usuario_criacao
            ? sociedade.codigo_usuario_criacao
            : 0,
      };

      let response = await api.excluirSociedade(request);
      if (response) {
        toast.success(response.message);
        sociedade.codigo_usuario_criacao = 0;
        limparCampos();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {dadosManutencaoVenda && (
        <>
          <div className="mt-1">
            <h4>Manutenção de Venda</h4>
          </div>

          <Container
            fluid
            className="border rounded p-3 bg-light shadow-sm mb-4"
          >
            <div className="table-responsive">
              <h5>Incluir/Remover Cônjuge ou Sócio do Contrato</h5>
              <Table striped bordered hover className="mt-4">
                <thead>
                  <tr className="text-center">
                    <th>Cônjuge/Sócio</th>
                    <th>Tipo</th>
                    <th>Assinar Contrato</th>
                    <th>Incluir/Remover Contrato</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosManutencaoVenda.length > 0 &&
                    dadosManutencaoVenda.map((item, index) => (
                      <tr
                        className="text-center"
                        key={index}
                        style={{
                          transition: 'background-color 0.1s ease',
                        }}
                      >
                        <td>{item.nome}</td>
                        <td>{item.tipo}</td>
                        <td className="text-center align-middle">
                          {
                            <>
                              <Button
                                variant={
                                  item.assina_contrato === 'S'
                                    ? 'danger'
                                    : 'success'
                                }
                                disabled={
                                  bloquearEdicaoDeRegistros(
                                    item.doc_digital_status
                                  ) || isLoading
                                }
                                type="submit"
                                className="d-inline-flex align-items-center"
                                onClick={() => {
                                  assinarOuNaoAssinarContrato(
                                    item,
                                    item.assina_contrato === 'S'
                                      ? 'Não Assinar'
                                      : 'Assinar'
                                  );
                                }}
                              >
                                <FaFileSignature />
                                <span className="ml-2 d-xs-none">
                                  {item.assina_contrato === 'S'
                                    ? 'Não Assinar'
                                    : 'Assinar'}
                                </span>
                              </Button>
                            </>
                          }{' '}
                        </td>
                        <td className="text-center align-middle">
                          {' '}
                          {
                            <>
                              <Button
                                variant={
                                  item.consta_no_contrato === 'S'
                                    ? 'danger'
                                    : 'success'
                                }
                                disabled={
                                  bloquearEdicaoDeRegistros(
                                    item.doc_digital_status
                                  ) || isLoading
                                }
                                type="submit"
                                className="d-inline-flex align-items-center"
                                onClick={() => {
                                  incluirOuRemoverSocio(
                                    item,
                                    item.consta_no_contrato === 'S'
                                      ? 'Remover'
                                      : 'Incluir'
                                  );
                                }}
                              >
                                <FaExclamationTriangle />
                                <span className="ml-2 d-xs-none">
                                  {item.consta_no_contrato === 'S'
                                    ? 'Remover'
                                    : 'Incluir'}
                                </span>
                              </Button>
                            </>
                          }
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {existeBloqueio() && (
                <>
                  <strong>
                    *Não é possível fazer modificações no contrato pois existe
                    um documento digital pendente de assinaturas.
                  </strong>
                </>
              )}
            </div>
          </Container>
          <hr />
          <div className="mt-3">
            <h5>Alterar Sociedade - Dividir Comissão</h5>
            <div className="mt-4">
              <Row className="mb-3">
                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="corretor">
                    <Form.Label>Corretor</Form.Label>
                    <Selector
                      noOptionsMessage={() => 'Nenhum corretor encontrado'}
                      placeholder="Pesquisar corretores..."
                      options={optionsCorretores}
                      onChange={selectedOption => {
                        setCorretorSelecionado(selectedOption);
                      }}
                      value={corretorSelecionado}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="porcentagem">
                    <Form.Label className="ms-auto">Porcentagem</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Informe a porcentagem..."
                      onChange={e => {
                        setPorcentagem(e.target.value);
                      }}
                      value={porcentagem}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-end text-end">
                <Col xs="auto">
                  <Button
                    variant="danger"
                    type="submit"
                    className="d-flex align-items-center"
                    onClick={() => {
                      excluirSociedade();
                    }}
                    disabled={isLoading}
                  >
                    <FaTrash />
                    <span className="ml-2 d-xs-none">Excluir</span>
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    variant={'light'}
                    type="submit"
                    className="d-flex align-items-center"
                    disabled={!porcentagem || !corretorSelecionado || isLoading}
                    onClick={() => {
                      salvarSociedade(porcentagem);
                    }}
                  >
                    <FaSave />
                    <span className="ml-2 d-xs-none">Salvar</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      {!dadosManutencaoVenda && (
        <div>
          <Loading></Loading>
        </div>
      )}
    </>
  );
};

export default DetalhesManutencaoVenda;
