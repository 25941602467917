import _keyBy from 'lodash/keyBy';

const packageJson = require('../package.json');

export const AUTH_KEY = 'AUTH_KEY';
export const HN = 'hn';
export const DEFAULT_ENV = 'prod';
export const USER = 'idsession';
export const ATENDENTE_LANCAMENTO = 'atendente_lancamento';
export const CARACTERISTICAS = 'caracteristicas';
export const APIBASE = 'https://api.setcorp.com.br';
// export const APIBASE = 'http://hml.setcorp.com.br/api';
//export const APIBASE = 'http://localhost:54608';
export const QUANTIDADEPORPAGINA = 10;
export const URL_LOGIN = `${process.env.PUBLIC_URL}/login`; // Utilizado para redirecionamento quando a sessão expirar
export const URL_BASE = process.env.PUBLIC_URL;
export const MASTER_VERSION = `${packageJson.version}`;
export const urlAdmVendas =
  'https://www.revobrasil.com.br/Extranet/Comercial/AdministracaoVendas.aspx';

export const EMPREENDIMENTO_LANCAMENTO = 0;
export const SEGUNDO_EMPREENDIMENTO_LANCAMENTO = 0;

export const ENV = [
  { value: 'prod', label: 'Produção' },
  { value: 'hml', label: 'Treinamento' },
];

export const TIPO_PESSOA = [
  { value: 0, label: 'Pessoa Física' },
  { value: 1, label: 'Pessoa Jurídica' },
];

export const PERFIL_COMPRADOR = [
  { value: 2, label: 'Morador' },
  { value: 1, label: 'Construtor' },
  { value: 3, label: 'Investidor' },
  { value: 4, label: 'Exterior' },
];

export const DESCRICOES_STATUS_COMPROVANTE = [
  { value: 0, label: 'Aguardando recebimento', cor: '#006400' },
  { value: 1, label: 'Aguardando comprovante', cor: '#ffc516' },
  { value: 2, label: 'Comprovante inserido', cor: '#3baf56' },
  { value: 3, label: 'Aprovado Sec. Vendas', cor: '#1E8BFF' },
  { value: 4, label: 'Aprovado Dpto. Financeiro', cor: '#619dd8' },
  { value: 8, label: 'Recusado Dpto. Financeiro', cor: '#ea9384' },
  { value: 9, label: 'Recusado', cor: '#dc3545' },
];

export const ERROS = {
  CPF_INDISPONIVEL: 'CPF já cadastrado',
  CPF_INVALIDO: 'CPF inválido, verifique',
  CPF_VINCULADO:
    'Não é possível alterar o CPF, entre em contato com a Sec. Vendas.',
  EMPREENDIMENTO_VINCULO_INVALIDO:
    'Prospecto não vinculado a um empreendimento válido. Tente vincular o prospecto a outro empreendimento.',
};

export const NIVELADM = 'ADM';
export const NIVELCOR = 'COR';
export const NIVELGER = 'GER';

export const HIE_NIVEL = {
  EF7892F77C74F4C0: NIVELADM,
  C382AF9E0F00AAAD: NIVELCOR,
  '289C55969055E344': NIVELGER,
};

export const TIPO_PERIODO_ESPECIFICO = 'Especifico';
export const TIPO_PERIODO_ESTOQUE = 'Estoque';
export const TIPO_PERIODO_LANCAMENTO = 'Lancamento';
export const TIPO_PERIODO = [
  { value: TIPO_PERIODO_ESPECIFICO, label: 'Específico' },
  { value: TIPO_PERIODO_ESTOQUE, label: 'Apenas Estoque' },
  { value: TIPO_PERIODO_LANCAMENTO, label: 'Apenas Lançamento' },
];

export const TIPO_VISAO_RANKING = [
  { value: 0, label: 'Vendas - VGV' },
  { value: 1, label: 'Vendas - Unidade' },
  { value: 2, label: 'Rescisões - VGV' },
  { value: 3, label: 'Rescisões - Unidade' },
  { value: 4, label: 'Recebíveis' },
  { value: 5, label: 'Quantidade Atendimentos Leads' },
  { value: 6, label: '% Conversão Atendimento' },
  { value: 7, label: 'Comissão Projetada' },
  { value: 8, label: 'Vendas - Efetivadas' },
];

export const TIPO_PESSOA_FISICA = TIPO_PESSOA[0];
export const TIPO_PESSOA_JURIDICA = TIPO_PESSOA[1];
export const TAMANHO_CPF = 11;
export const TAMANHO_CEP = 8;

export const TIPO_CADASTRO = [
  { value: 0, label: 'Sem Documentos' },
  { value: 1, label: 'Incompleto' },
  { value: 2, label: 'Completo' },
  { value: 3, label: 'Conferido' },
];

export const SEXO = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' },
];

export const ESTADOS = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'PR', label: 'Paraná' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'TO', label: 'Tocantins' },
];

export const ORGAO_EMISSOR_CNH = [
  { value: 'DETRAN/AC', label: 'DETRAN/AC' },
  { value: 'DETRAN/AL', label: 'DETRAN/AL' },
  { value: 'DETRAN/AM', label: 'DETRAN/AM' },
  { value: 'DETRAN/AP', label: 'DETRAN/AP' },
  { value: 'DETRAN/BA', label: 'DETRAN/BA' },
  { value: 'DETRAN/CE', label: 'DETRAN/CE' },
  { value: 'DETRAN/DF', label: 'DETRAN/DF' },
  { value: 'DETRAN/ES', label: 'DETRAN/ES' },
  { value: 'DETRAN/GO', label: 'DETRAN/GO' },
  { value: 'DETRAN/MA', label: 'DETRAN/MA' },
  { value: 'DETRAN/MG', label: 'DETRAN/MG' },
  { value: 'DETRAN/MS', label: 'DETRAN/MS' },
  { value: 'DETRAN/MT', label: 'DETRAN/MT' },
  { value: 'DETRAN/PA', label: 'DETRAN/PA' },
  { value: 'DETRAN/PB', label: 'DETRAN/PB' },
  { value: 'DETRAN/PE', label: 'DETRAN/PE' },
  { value: 'DETRAN/PI', label: 'DETRAN/PI' },
  { value: 'DETRAN/PR', label: 'DETRAN/PR' },
  { value: 'DETRAN/RJ', label: 'DETRAN/RJ' },
  { value: 'DETRAN/RN', label: 'DETRAN/RN' },
  { value: 'DETRAN/RO', label: 'DETRAN/RO' },
  { value: 'DETRAN/RR', label: 'DETRAN/RR' },
  { value: 'DETRAN/RS', label: 'DETRAN/RS' },
  { value: 'DETRAN/SC', label: 'DETRAN/SC' },
  { value: 'DETRAN/SE', label: 'DETRAN/SE' },
  { value: 'DETRAN/SP', label: 'DETRAN/SP' },
  { value: 'DETRAN/TO', label: 'DETRAN/TO' },
];

export const ESTADO_CIVIL_CLIENTE = [
  { value: 0, label: 'Solteiro' },
  { value: 1, label: 'Casado em regime de comunhão de bens' },
  { value: 2, label: 'Casado em regime de comunhão parcial de bens' },
  { value: 3, label: 'Casado em regime de comunhão universal de bens' },
  { value: 4, label: 'Casado em regime de participação final nos aquestos' },
  { value: 5, label: 'Casado em regime de separação total de bens' },
  { value: 6, label: 'União Estável' },
  { value: 7, label: 'Divorciado' },
  { value: 8, label: 'Separado Judicialmente' },
  { value: 9, label: 'Viúvo' },
];

export const ESTADO_CIVIL_CS = [
  { value: 1, label: 'Cônjuge / Rep. Legal' },
  { value: 2, label: 'Sócio' },
  { value: 3, label: 'Cônjuge do sócio' },
  { value: 4, label: 'Outros' },
  { value: 5, label: 'Procurador' },
  { value: 6, label: 'Cônjuge do procurador' },
];

export const PROSPECTO_STATUS_ENUM = {
  nao_iniciado: 1,
  em_andamento: 2,
  proposta_realizada: 3,
  montagem_de_pasta: 5,
  pasta_completa: 8,
  descartado: 9,
  pre_venda_efetivada: 10,
  contrato_enviado: 11,
  sinal_quitado: 12,
};

export const PROSPECTO_STATUS = [
  { value: PROSPECTO_STATUS_ENUM.nao_iniciado, label: 'Não iniciado' },
  { value: PROSPECTO_STATUS_ENUM.em_andamento, label: 'Em Andamento' },
  {
    value: PROSPECTO_STATUS_ENUM.proposta_realizada,
    label: 'Pré-venda Realizada',
  },
  {
    value: PROSPECTO_STATUS_ENUM.montagem_de_pasta,
    label: 'Montagem de Pasta',
  },
  { value: PROSPECTO_STATUS_ENUM.pasta_completa, label: 'Pasta Completa' },
  { value: PROSPECTO_STATUS_ENUM.descartado, label: 'Descartado' },
  {
    value: PROSPECTO_STATUS_ENUM.pre_venda_efetivada,
    label: 'Pré-venda Efetivada',
  },
  { value: PROSPECTO_STATUS_ENUM.contrato_enviado, label: 'Contrato Enviado' },
  { value: PROSPECTO_STATUS_ENUM.sinal_quitado, label: 'Sinal Quitado' },
];

export const STATUS_ETAPAS_PROSPECTOS = [
  { codigo: 0, descricao: 'Todos' },
  { codigo: 2, descricao: 'Em Andamento' },
  { codigo: 5, descricao: 'Montagem de Pasta' },
  { codigo: 8, descricao: 'Pasta Completa' },
  { codigo: 3, descricao: 'Finalizado com Venda' },
  { codigo: 9, descricao: 'Descartado' },
];

export const PROSPECTO_ORIGEM_CADASTRO = [
  { value: 1, label: 'Extranet' },
  { value: 2, label: 'Lead' },
  { value: 3, label: 'Hotsite' },
];

export const TIPOS_PESQUISA = {
  DISSERTATIVA: 'DISSERTATIVA',
  ESCOLHA: 'ESCOLHA',
  PESO: 'PESO',
  ESTRELA: [10],
};

export const TIPO_TITULO = {
  BOLETO: 1,
  CHEQUE: 2,
  DINHEIRO: 3,
  CARTAO: 4,
  DEPOSITO: 5,
  DUPLICATA: 6,
};

export const FORMAS_PAGAMENTO = [
  { value: TIPO_TITULO.BOLETO, label: 'Boleto' },
  { value: TIPO_TITULO.CHEQUE, label: 'Cheque' },
  { value: TIPO_TITULO.CARTAO, label: 'Cartão' },
  { value: TIPO_TITULO.DEPOSITO, label: 'Deposito/PIX' },
  { value: TIPO_TITULO.DINHEIRO, label: 'Dinheiro' },
];

export const FORMAS_PAGAMENTO_BY_CODIGO = _keyBy(FORMAS_PAGAMENTO, 'value');

export const BOLETO = FORMAS_PAGAMENTO[0].value;
export const CHEQUE = FORMAS_PAGAMENTO[1].value;
export const CARTAO = FORMAS_PAGAMENTO[2].value;
export const DEPOSITO = FORMAS_PAGAMENTO[3].value;
export const DINHEIRO = FORMAS_PAGAMENTO[4].value;

export const DIAS_TOLERANCIA_VENCIMENTO_CHEQUE_DEPOSITO = 10;

export const TIPO_HISTORICO_PROPOSTA_RAPIDA = {
  INCLUSAO_DOCUMENTO: 1,
  REMOCAO_DOCUMENTO: 2,
  APROVACAO: 3,
  RECUSA: 4,
  RECEBIMENTO_APROVADO: 5,
  BAIXA_CONFIRMADA: 6,
};

export const TIPO_HISTORICO_PROPOSTA_RAPIDA_CORES = [
  {
    value: TIPO_HISTORICO_PROPOSTA_RAPIDA.INCLUSAO_DOCUMENTO,
    color: '#1E8BFF',
  },
  { value: TIPO_HISTORICO_PROPOSTA_RAPIDA.REMOCAO_DOCUMENTO, color: '#17A2B8' },
  { value: TIPO_HISTORICO_PROPOSTA_RAPIDA.APROVACAO, color: '#28A745' },
  { value: TIPO_HISTORICO_PROPOSTA_RAPIDA.RECUSA, color: '#dc3545' },
  {
    value: TIPO_HISTORICO_PROPOSTA_RAPIDA.RECEBIMENTO_APROVADO,
    color: '#28A745',
  },
  { value: TIPO_HISTORICO_PROPOSTA_RAPIDA.BAIXA_CONFIRMADA, color: '#28A745' },
];

export const QUESTION_MAX_LENGTH = { id: 11, length: 5000 };

export const MASK_DATE = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const MASK_CPF = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const MASK_CNPJ = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const MASK_CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const MASK_CEL = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const MASK_CEL_9 = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const DATE_DEFAULT_FORMAT = 'DD/MM/YYYY';
export const DATETIME_DEFAULT_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATETIME_DEFAULT_SHORT_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATETIME_DEFAULT_SUPER_SHORT_FORMAT = 'DD/MM HH:mm';
export const DATE_FORMAT_SERVER = 'YYYY-MM-DDTHH:mm:ss';

export const CODIGO_TIPO_PROSPECTO = 1; // Utilizado no cadastro do prospecto para apontar que não é um sócio ou conjuge

export const CODIGO_ORIGEM_CADASTRO_PROSPECTO = 1; // Descreve que o prospecto cadastrado originou da extranet

export const MAX_SIZE_DOCUMENTO = 500000; // bytes

export const TIPO_UPLOAD_PDF = 'application/pdf';
export const TIPOS_UPLOAD_DOCUMENTO_PROSPECTO = [TIPO_UPLOAD_PDF, 'image/*'];

export const PERGUNTAS_PARENTES = {};

//Pusher
export const pusherConfig = {
  id: '26537dd9c4f052c7a7f2',
  cluster: 'us2',
};

//Audio Senha
export const audio1 = '/audio/definite.ogg';
export const audio2 = '/audio/allusive.ogg';
export const audio3 = '/audio/alarm-frenzy.ogg';
export const audio4 = '/audio/long-chime-sound.ogg';

//Documento Digital
export const TiposGruposDocumentoDigital = {
  Compradores: 1,
  Testemunhas: 2,
  Procuradores: 3,
  Validadores: 4,
  Intervenientes: 5,
};

export const TiposSignatarios = {
  Assinante: 0,
  Parte: 1,
  Testemunha: 2,
  Procurador: 3,
  Aprovador: 4,
};

export const StatusDocumentoDigital = {
  NovaSolicitacao: 0,
  Solicitado: 1,
  Aprovado: 2,
  Reprovado: 3,
  Descartado: 4,
  PendenteAssinaturasClientes: 5,
  PendenteAssinaturasSetpar: 6,
  Cancelado: 7,
  Finalizado: 8,
  Uploaded: 9,
};

export const TipoEventoDocumentoDigital = {
  Solicitacao: 1,
  SignatarioVinculado: 2,
  SignatarioDesvinculado: 3,
  Reprovacao: 4,
  Descarte: 5,
  ReSolicitacao: 6,
  Aprovacao: 7,
  Upload: 8,
  NotificacaoEnviada: 9,
  AssinaturaRealizada: 10,
  Cancelamento: 11,
  Finalizacao: 12,
  SignatarioEditado: 13,
  IncluirCartaDesconto: 14,
};

export const TiposDocDigital = {
  ContratoVenda: 1,
  CLDB: 2,
  RefinanciamentoAditamento: 3,
  RescisaoRepasse: 4,
  RescisaoBilateral: 5,
  TransferenciaTitularidade: 6,
};

export const CorEventoDocDigital = [
  {
    value: TipoEventoDocumentoDigital.Solicitacao,
    color: '#20c997',
  },
  { value: TipoEventoDocumentoDigital.SignatarioVinculado, color: '#17A2B8' },
  {
    value: TipoEventoDocumentoDigital.SignatarioDesvinculado,
    color: '#ffc107',
  },
  { value: TipoEventoDocumentoDigital.Reprovacao, color: '#dc3545' },
  { value: TipoEventoDocumentoDigital.Cancelamento, color: '#dc3545' },
  { value: TipoEventoDocumentoDigital.Descarte, color: '#6c757d' },
  { value: TipoEventoDocumentoDigital.Aprovacao, color: '#28A745' },
  { value: TipoEventoDocumentoDigital.Upload, color: '#007bff' },
  { value: TipoEventoDocumentoDigital.Finalizacao, color: '#28A745' },
  { value: TipoEventoDocumentoDigital.AssinaturaRealizada, color: '#17A2B8' },
  { value: TipoEventoDocumentoDigital.IncluirCartaDesconto, color: '#28A7CE' },
];

export const DescricoesTiposDocDigital = {
  [TiposDocDigital.ContratoVenda]: 'Contrato de Venda',
  [TiposDocDigital.CLDB]: 'Contrato de CLDB',
  [TiposDocDigital.RefinanciamentoAditamento]: 'Refinanciamento/Aditamento',
  [TiposDocDigital.RescisaoBilateral]: 'Rescisão Bilateral',
  [TiposDocDigital.RescisaoRepasse]: 'Rescisão para Repasse',
  [TiposDocDigital.TransferenciaTitularidade]: 'Transferência de Titularidade',
};

export const gruposContratoVenda = [
  {
    codigo: TiposGruposDocumentoDigital.Compradores,
    tipo: TiposGruposDocumentoDigital.Compradores,
    nome: 'compradores',
    seguir_ordem_interna: false,
    todos_devem_assinar: true,
  },
  {
    codigo: TiposGruposDocumentoDigital.Procuradores,
    tipo: TiposGruposDocumentoDigital.Procuradores,
    nome: 'procuradores',
    seguir_ordem_interna: false,
    todos_devem_assinar: false,
    minimo_assinaturas: 1,
  },
  {
    codigo: TiposGruposDocumentoDigital.Testemunhas,
    tipo: TiposGruposDocumentoDigital.Testemunhas,
    nome: 'testemunhas',
    seguir_ordem_interna: false,
    todos_devem_assinar: false,
    minimo_assinaturas: 2,
  },
  {
    codigo: TiposGruposDocumentoDigital.Validadores,
    tipo: TiposGruposDocumentoDigital.Validadores,
    nome: 'validadores',
    seguir_ordem_interna: false,
    todos_devem_assinar: false,
    minimo_assinaturas: 1,
  },
];

export const recorrenciaDuracoes = {
  anual: 12,
  semestral: 6,
  quadrimestral: 4,
  trimestral: 3,
  avulsa: 12,
};

export const StatusImovel = {
  Disponivel: 0,
  Vendido: 1,
  Bloqueado: 2,
  PreVenda: 3,
  PropostaRapida: 4,
  Reservado: 5,
};
