import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { DATETIME_DEFAULT_FORMAT } from '~/constant';
import { format } from '~/Utils/Helpers';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AuthStorage from '~/Utils/AuthStorage';
import * as api from '~/Services/Service';

const ModalPendente = ({
  mostrarModal,
  setMostrarModal,
  proposta,
  setProposta,
}) => {
  const [motivoPendencia, setMotivoPendencia] = useState('');
  const [statusSelecionado, setStatusSelecionado] = useState(null);
  const [historicoConferencia, setHistoricoConferencia] = useState([]);
  const [
    habilitarCampoJustificativa,
    setHabilitarCampoJustificativa,
  ] = useState(false);
  const [ultimoUsuarioConferencia, setUltimoUsuarioConferencia] = useState(
    null
  );
  const [opcoes, setOpcoes] = useState([]);
  const [ultimaOpcaoSelecionada, setUltimaOpcaoSelecionada] = useState('');

  useEffect(() => {
    if (mostrarModal) {
      buscarHistoricoConferencia();
    }
  }, [mostrarModal]);

  useEffect(() => {
    if (historicoConferencia.length > 0) {
      construirOpcoes();
      setUltimaOpcaoSelecionada(historicoConferencia[0].status_atual);
      bloquearConferencia();
    }
  }, [historicoConferencia]);

  const handleMotivoChange = e => {
    setMotivoPendencia(e.target.value);
  };

  const alterarConferencia = e => {
    switch (e.target.value) {
      case '2':
        setStatusSelecionado({ codigo: 2, descricao: 'Pendente' });
        setHabilitarCampoJustificativa(true);
        break;
      case '3':
        setStatusSelecionado({ codigo: 3, descricao: 'Conferido' });
        setHabilitarCampoJustificativa(false);
        setMotivoPendencia('');
        break;
      case '4':
        setStatusSelecionado({ codigo: 4, descricao: 'Ajustado' });
        setHabilitarCampoJustificativa(false);
        setMotivoPendencia('');
    }
  };

  const buscarHistoricoConferencia = async () => {
    try {
      const response = await api.obterConferenciaDocumento(proposta.pre_venda);
      if (response) {
        setHistoricoConferencia(response);
        setUltimoUsuarioConferencia(
          response.reverse()[0].codigo_usuario_conferencia
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const editarConferencia = async () => {
    try {
      if (!statusSelecionado) {
        toast.error('É necessário informar o status para salvar');
        return;
      }
      if (ultimaOpcaoSelecionada == statusSelecionado.descricao) {
        toast.error('O registro já está nesse status de conferência');
        return;
      }
      const request = {
        codigo_proposta: proposta.codigo_proposta,
        codigo_usuario: AuthStorage.getUser().usuario,
        status_conferencia: statusSelecionado.codigo,
        justificativa: motivoPendencia,
      };

      const retorno = await api.conferirDocumento(request);
      if (retorno) {
        toast.success(retorno.message);
      }
      buscarHistoricoConferencia();
      const propostaCopy = proposta;
      propostaCopy.conferido = statusSelecionado.descricao;
      setProposta(propostaCopy);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const bloquearConferencia = () => {
    if (
      ultimaOpcaoSelecionada === 'Pendente' &&
      AuthStorage.getUser().usuario === proposta.codigo_corretor
    ) {
      return false;
    }

    if (
      !AuthStorage.getCaracteristicas().find(
        caracteristica => caracteristica === 277
      )
    ) {
      // 277 -> Sec Vendas
      return true;
    }
    if (historicoConferencia.length == 1) {
      return false;
    }
    if (
      ultimoUsuarioConferencia == AuthStorage.getUser().usuario ||
      AuthStorage.getCaracteristicas().find(
        caracteristica => caracteristica === 549
      ) //Altera conferência de vendas
    ) {
      return false;
    }
    return true;
  };

  const construirOpcoes = () => {
    let opcoes = [
      {
        value: '',
        label: 'Selecione...',
      },
    ];
    if (
      historicoConferencia.length > 0 &&
      historicoConferencia.reverse()[0].status_atual === 'Pendente' &&
      AuthStorage.getUser().usuario === proposta.codigo_corretor
    ) {
      opcoes.push({
        value: '4',
        label: 'Ajustado',
      });
    } else {
      opcoes.push(
        {
          value: '2',
          label: 'Pendente',
        },
        {
          value: '3',
          label: 'Conferido',
        }
      );
    }
    setOpcoes(opcoes);
  };

  return (
    <Modal
      show={mostrarModal}
      onHide={() => setMostrarModal(false)}
      size={'lg'}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Visualizar/Editar Conferência</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
        }}
      >
        <section>
          <strong className="d-block text-center">
            Proposta n°{proposta.codigo_proposta}
          </strong>
          <div className="d-flex flex-column">
            <Row>
              <Col md="6">
                <select
                  name="conferidoSelect"
                  className="form-control mt-4"
                  onChange={e => alterarConferencia(e)}
                  disabled={bloquearConferencia()}
                >
                  {opcoes.map(opcao => (
                    <option key={opcao.value} value={opcao.value}>
                      {opcao.label}
                    </option>
                  ))}
                </select>
              </Col>
              {habilitarCampoJustificativa && (
                <Col md="6">
                  <label htmlFor="motivoPendencia" className="form-label mt-4">
                    Motivo da Pendência:
                  </label>
                  <textarea
                    id="motivoPendencia"
                    className="form-control"
                    onChange={e => handleMotivoChange(e)}
                    rows="4"
                    placeholder="Descreva o motivo da pendência..."
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col className="col-md-6">
                <div>
                  <Button
                    variant="primary"
                    type="button"
                    className="form-control mt-4"
                    onClick={async () => {
                      await editarConferencia();
                    }}
                    disabled={bloquearConferencia()}
                  >
                    <FaSave />
                    <span className="ml-2 d-xs-none">Editar status</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Timeline lineColor={'#ddd'} animate className="ml-auto">
            {historicoConferencia.map((el, index) => {
              let cor;
              switch (el.status_atual) {
                case 'Não conferido':
                  cor = '#FF6347';
                  break;
                case 'Pendente':
                  cor = '#FFA500';
                  break;
                case 'Conferido':
                  cor = '#32CD32';
                  break;
                case 'Ajustado':
                  cor = '#87CEEB';
                  break;
              }
              return (
                <TimelineItem
                  key={index}
                  dateText={format.date(el.data, DATETIME_DEFAULT_FORMAT)}
                  dateInnerStyle={{
                    background: cor,
                  }}
                  style={{
                    color: cor,
                  }}
                >
                  <>
                    <h5>{el.status_atual}</h5>
                    <p className="mt-2">
                      {' '}
                      <strong> Usuário alteração :</strong>{' '}
                      {el.usuario_conferencia}
                    </p>
                    {el.justificativa && (
                      <>
                        <p className="mt-2">
                          {' '}
                          <strong> Justificativa :</strong> {el.justificativa}
                        </p>
                      </>
                    )}
                  </>
                </TimelineItem>
              );
            })}
          </Timeline>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant={'light'}
            type="submit"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setMostrarModal(false);
            }}
          >
            <FaWindowClose />
            <span className="ml-2 d-xs-none">Fechar</span>
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPendente;
